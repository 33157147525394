const About = () => {
    return ( 
        <div>
            <div>
            This section is under construction :/
            </div>
        </div>
     )
}

export default About