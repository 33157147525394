import { useEffect, useState } from "react"

const Gallery = () => {

    return ( 
        <div>
            Under construction :/
        </div>
     )
}
 
export default Gallery